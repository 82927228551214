<template>
  <div>
    <clock-timeline-start :transaction="clockTransaction" :time="clockTransaction.start"/>
    <clock-timeline-break
      v-for="tran in clockTransaction.break"
      :key="tran.startBreak"
      :transaction="tran"
      />
    <clock-timeline-end v-if="clockTransaction.end" :transaction="clockTransaction" :time="clockTransaction.end"/>
  </div>
</template>

<script>
import ClockTimelineStart from './ClockTimelineStart'
import ClockTimelineEnd from './ClockTimelineEnd'
import ClockTimelineBreak from './ClockTimelineBreak'
export default {
  components: {
    ClockTimelineStart,
    ClockTimelineEnd,
    ClockTimelineBreak
  },
  props: ['data'],
  computed: {
    clockTransaction () {
      return this.data[0]
    }
  }
}
</script>
