<template>
  <div class="filter">
    <div class="filter-title flex flex-center">
      <p>Filters</p>
    </div>
    <div></div>
    <div class="filter-items">
      <div class="filter-item is-active" @click="onFilter">Last 7 day</div>
      <div class="filter-item " :class="filterUnapprove ? 'is-active' : ''" @click="$emit('update:filterUnapprove', !filterUnapprove)">Unapproved time clocks</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['filter', 'filterUnapprove'],
  mounted () {
    this.onFilter()
  },
  methods: {
    Last7Days () {
      var result = []
      for (var i = 0; i < 7; i++) {
        var d = new Date()
        d.setDate(d.getDate() - i)
        result.push(d)
      }
      return (result)
    },
    getLast7Date () {
      const daysArr = this.Last7Days()
      return daysArr[daysArr.length - 1]
    },
    toDateFilterFormat (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onFilter () {
      const daysArr = this.Last7Days()
      const from = this.toDateFilterFormat(daysArr[daysArr.length - 1])
      const to = this.toDateFilterFormat(daysArr[0])
      this.$emit('update:filter', { from: from, to: to })
      this.$emit('update')
    }
  }
}
</script>

<style lang="css" scoped>
  .filter {
    height: 60px;
    background: var(--tgLight);
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 70px 3px auto;
  }

  .filter-title > p {
    font-size: .8em;
    height: 1.2em;
    font-weight: 600;
    text-align: center;
    color: var(--tgBlue)
  }

  .filter-items {
    display: inline-flex;
    align-self: center;
    flex-basis: 10px;
  }
  .filter-item {
    max-height: 36px;
    padding: 8px;
    border-radius: 5px;
    background: #C7C7C7;
    font-size: 0.8em;
    margin-right: 12px;
    cursor: pointer;
  }

  .flex {
    display: flex;
  }
  .flex-center {
    justify-content: center;
    align-items: center;
  }
  .filter-item.is-active {
    background: var(--tgDarkBlue);
    color: white;
    font-weight: 600;
  }

</style>
