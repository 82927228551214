<template>
  <div class="clock-duration">
  <p class="title">Time clock duration</p>
  <b class="time">{{ data.timeDiff }}</b>
  <p class="title">{{ data.status }}</p>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style lang="css" scoped>
  .clock-duration {
    background: var(--forground);
    padding: 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .clock-duration > .time {
    font-size: 3em;
    font-weight: 400;
  }

  .clock-duration > .title {
    font-size: .8em;
  }
</style>
