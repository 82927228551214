'use strict'
const axios = require('../common/@axios')
const filterHelper = require('../common/filterHelper')

const ReportService = {
  getTrasactionReport (filters = {}) {
    const _filters = filterHelper.toQueryStrings(filters)
    const url = `/report/transactions${_filters}`
    console.log(url)
    return axios.get(url)
  },
  getTrasactionWithStaffId (staffId, filters = {}) {
    const _filters = filterHelper.toQueryStrings(filters)
    return axios.get(`/report/transactions${_filters}`)
  },
  getTrasactionWithTransactionId (transacitonId, filters = {}) {
    const _filters = filterHelper.toQueryStrings(filters)
    return axios.get(`/report/transactions/${transacitonId}${_filters}`)
  }
}

const filters = { from: '2020-01-01', to: '2020-01-31' }
ReportService.getTrasactionReport(filters).then(response => {
  console.log(response.data)
}).catch(e => {
  console.log(e)
})

module.exports = ReportService
