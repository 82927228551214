<template>
<div class="table-view-cell" @click="$emit('click')">
  <div class="grid">
    <div class="date padding-10">
    </div>
    <div class="status-color "></div>
    <div class="detail padding-10">
      <i :class="iconClass" class="mr-10"></i> <slot></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['iconClass']
}
</script>

<style lang="css" scoped>
  .table-view-cell {
    padding: 8px;
    min-height: 60px;
    margin-top: 4px;
    background: white;
    box-shadow: 0 1px 1px rgba(1, 1, 1, 0.082);
    cursor: pointer;
  }
  .detail {
    padding: 16px;
    display: flex;
    flex-flow: row;
    align-items: center;
    text-transform: uppercase;
  }

  .mr-10 {
    padding-right: 10px;
  }
</style>
