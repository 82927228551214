<template>
<transition name="fade">
  <div class="timeline_wrapper">
    <div class="timeline__box">
      <div class="flex timeline__box__item ">
        <span class="status start"></span>
        <span class="text">In</span>
        <span class="time">{{ timeStart }}</span>
      </div>
      <div class="flex timeline__box__item clickable" @click="isShowBreakDetail = !isShowBreakDetail">
        <span class="status break"></span>
        <span class="text">Break  <i class="ligth-text" :class="isShowBreakDetail ? 'fa fa-arrow-down' : 'fa fa-arrow-right'" aria-hidden="true"></i></span>
        <span class="time">{{ totalBreakMin }} mins</span>
      </div>
      <transition name="fade">
        <div class="break-detail" v-if="isShowBreakDetail">
          <div class="time__detail" v-for="(tran, index) in transactions[0].break" :key="index">
            <span class="text">#{{ index + 1 }}</span>
            <span class="time">{{ breakTimeLine(tran.startBreak, tran.endBreak) }}</span>
          </div>
        </div>
      </transition>
      <div class="flex timeline__box__item">
        <span class="status end"></span>
        <span class="text">Out</span>
        <span class="time">-</span>
      </div>
      <div class="flex timeline__box__total">
        <span class="status end">Total: {{ totalWorkWithOutBreakDuration }}</span>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import moment from 'moment'
export default {
  props: ['transactions'],
  data () {
    return {
      isShowBreakDetail: false
    }
  },
  computed: {
    timeStart () {
      const date = this.transactions[0].start
      return moment(date).format('HH:mm:ss')
    },
    totalBreakDuration () {
      const totalDurationMillisec = this.transactions[0].break.reduce((con, prev) => {
        return con + prev.duration
      }, 0)
      return totalDurationMillisec
    },
    totalBreakMin () {
      const totalDurationMillisec = this.totalBreakDuration
      const milliToMin = (1000 * 60)
      const totalDurationMins = totalDurationMillisec / milliToMin
      return parseInt(totalDurationMins)
    },
    totalWorkDuration () {
      const startWork = moment(this.transactions[0].start)
      const now = moment(new Date())
      const timeDiffMill = now.diff(startWork)
      return timeDiffMill
    },
    totalWorkWithOutBreakDuration () {
      const totalWorkDurationMillisec = this.totalWorkDuration
      const totalBreakDurationMillisec = this.totalBreakDuration
      const diffTimeMillisec = totalWorkDurationMillisec - totalBreakDurationMillisec
      return this.millisecToTextHour(diffTimeMillisec)
    }
  },
  methods: {
    breakTimeLine (from, to) {
      if (to) {
        return `${this.format24HourSec(from)} - ${this.format24HourSec(to)}`
      } else {
        return `${this.format24HourSec(from)} - `
      }
    },
    millisecToTextHour (mili) {
      try {
        // Process Time diff
        const diffMins = Math.floor(mili / (1000 * 60))
        const diffHours = Math.floor(mili / (1000 * 60 * 60))
        const diffSecs = Math.floor(mili / (1000))

        const min = parseInt(diffMins) % 60
        const sec = parseInt(diffSecs) % 60
        let minText = `${min}`
        if (min < 10) {
          minText = `${min}`
        }

        const hour = diffHours
        return `${hour}h ${minText}m ${sec}s` // 0.13hr
      } catch (e) {
        console.log(e)
        throw e
      }
    }
  }
}
</script>

<style lang="css" scoped>
.timeline_wrapper {
  display: grid;
  grid-template-columns: auto 320px auto;
  padding: 16px;
}

.timeline__box {
  padding: 12px;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-flow: column;
  grid-column-start: 2;
  box-shadow: 1px 1px 10px rgba(1, 1, 1, .2);
}

.timeline__box__item {
  display: grid;
  margin-top: 8px;
  grid-template-columns: 16px 8px 30px auto auto 16px;
}

.timeline__box__item:nth-child(1) {
  margin-top: 0px;
}
.timeline__box__item {
  font-size: .8em;
}

.timeline__box__item .status {
  height: 20px;
  width: 20px;
  grid-column-start: 2;
  border-radius: 20px;
}

.timeline__box__item .text {
  grid-column-start: 4;
  grid-column-end: 5;
  text-align: left;
}

.timeline__box__item .time {
  grid-column-start: 5;
  grid-column-end: 6;
  text-align: right;
}

.start {
  background: var(--tgBlue);
}

.end {
  background: var(--unapproved);
}

.break {
  background: var(--pending);
}

.timeline__box__total {
  display: flex;
  justify-content: center;
}

.timeline__box__total > span {
  margin-top: 8px;
  text-align: center;
  background: white;
}

.time__detail {
  display: grid;
  grid-template-columns: 55px auto auto 16px;
  font-size: .8em;
  line-height: 1.8em;
  padding-top: 8px;
  color: var(--gray)
}

.time__detail > .text {
  grid-column-start: 2;
}

.time__detail > .time {
  grid-column-start: 3;
  grid-column-end: 4;
  text-align: right;
}

.ligth-text {
  color: var(--gray);
  font-size: .8em;
}
.flex {

}
</style>
