import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'
// import { AmplifyEventBus } from 'aws-amplify-vue'

import store from '../store'
// import Home from '../views/Home.vue'
import DashboardApp from '../views/app/Dashboard/Dashboard'
import TimeClockApp from '../views/app/TimeClock/TimeClock'
import StaffApp from '../views/app/Staff/StaffList/Staff'
import StaffDetailApp from '../views/app/Staff/StaffDetail'
import StaffAddStaffApp from '../views/app/Staff/StaffAddStaff'
import MoreApp from '../views/app/More/More'
import ReportApp from '../views/app/Report/Report'
import LoginApp from '../views/app/Auth/Login'
import LogoutApp from '../views/app/Auth/Logout'
import ForgotYourPassword from '../views/app/Auth/ForgotPassword'
import ConfirmOTP from '../views/app/Auth/ConfirmOTP'
import Register from '../views/app/Auth/Register'
import TimeClockDetailApp from '../views/app/TimeClockDetail/TimeClockDetail'
import Clock from '../views/app/Clock/Clock'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Clock,
    meta: {
      protect: true
    }
  },
  {
    path: '/dashboard',
    name: 'DashboardApp',
    component: DashboardApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/time-clock',
    name: 'TimeClockApp',
    component: TimeClockApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/staff',
    name: 'StaffApp',
    component: StaffApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/staff/new',
    name: 'StaffAddStaffApp',
    component: StaffAddStaffApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/staff/:staffId',
    name: 'StaffDetailApp',
    component: StaffDetailApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/time-clock/:transactionId',
    name: 'TimeClockDetailApp',
    component: TimeClockDetailApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/more',
    name: 'MoreApp',
    component: MoreApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/report',
    name: 'ReportApp',
    component: ReportApp,
    meta: {
      protect: true
    }
  },
  {
    path: '/inout',
    name: 'InOut',
    component: Clock,
    meta: {
      protect: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginApp
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutApp
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  { path: '/confirm', name: 'ConfirmOTP', props: true, component: ConfirmOTP },
  {
    path: '/forgot-password',
    name: 'ForgotYourPassword',
    component: ForgotYourPassword
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.debug(route)
  if (to.meta.protect) {
    try {
      await Auth.currentAuthenticatedUser()
      store.dispatch('setUserSignedInState', true)
      next()
    } catch (e) {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})

export default router
