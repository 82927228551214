<template>
  <the-layout class="container mt32">
    <div class="auth" slot="content">
    <div class="login">
      <h2>PHACENIX Login</h2>
      <form class="login__from" @submit="submitLogin">
        <div class="form-group">
          <label for="exampleInputEmail1">Telephone</label>
          <input type="tel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="096 666 6666" v-model="telephone" minlength="10" maxlength="10">
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" v-model="password">
        </div>
        <div class="login__action text-right mt16" >
          <router-link :tag="a" to="/forgot-password">Forget your password?</router-link>
        </div>
        <small id="loginHelp" class="form-text text-danger text-center" v-if="errorResponse">{{ errorResponse }}</small>
        <button type="submit" class="btn btn-tgblue mt32 btn-lg">Login</button>
        <router-link to="/register" class="btn mt16">Register</router-link>
      </form>
    </div>
    </div>
  </the-layout>
</template>

<script>
import { Auth } from 'aws-amplify'
import TheLayout from '@/components/TheLayout'
export default {
  components: {
    TheLayout
  },
  data () {
    return {
      telephone: '',
      password: '',
      userRole: 'staff',
      error: [],
      errorResponse: ''
    }
  },
  methods: {
    async submitLogin (e) {
      let phone = this.telephone
      const password = this.password

      if (phone.length !== 10) {
        return
      }

      phone = phone.replace('0', '+66')

      Auth.signIn(phone, password).then(async (response) => {
        this.signedIn = true

        try {
          const user = await Auth.currentAuthenticatedUser()
          const userGroups = user.signInUserSession.accessToken.payload['cognito:groups']
          if (userGroups.indexOf('owner') !== -1) {
            this.userRole = 'owner'
          }
          this.$store.dispatch('setUserRole', this.userRole)
        } catch (err) {
          this.userRole = 'staff'
        }

        this.$router.push({ path: '/' })
      }).catch(e => {
        if (e.code === 'NotAuthorizedException' || e.code === 'UserNotFoundException') {
          this.errorResponse = 'Incorrect username or password.'
        } else if (e.code === 'UserNotConfirmedException') {
          this.$router.push({ name: 'ConfirmOTP', params: { phone: this.telephone } })
        } else {
          this.errorResponse = e.message
        }
      })

      e.preventDefault()
    }
  }
}
</script>

<style lang="css" scoped>
  .login {
    padding: 16px;
  }
  .login__from {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding-top: 16px;
  }
  .btn-tgblue {
    background: var(--tgBlue);
    color: white;
  }
  #loginHelp {
    font-size: 1.1em;
    margin: 10px;
  }

  .mt16 {
    margin-top: 16px;
  }
  .mt32 {
    margin-top: 32px;
  }
</style>
