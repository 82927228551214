const ReportService = require('../services/report.service')
export default {
  state: {},
  actions: {
    fetchTransaction (context, fitlers) {
      return ReportService.getTrasactionReport(fitlers).then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
      })
    },
    fetchTransactionWithId (context, transactionId) {
      return ReportService.getTrasactionWithTransactionId(transactionId).then(response => {
        return response.data.transactions
      }).catch(e => {
        console.log(e)
      })
    }
  },
  mutations: {}
}
