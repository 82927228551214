<template>
  <div class="map-display">
    <GmapMap ref="mapRef" style="width: 100%; height: 300px"
      :zoom="15"
      :center="center"
      map-type-id="terrain"
    >
    <GmapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      :icon="m.icon"
      @click="center = m.center"
    />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: [
    'data'
  ],
  data () {
    return {
      center: {
        lat: this.data.locationStart.latitude,
        lng: this.data.locationStart.longitude
      },
      markers: [],
      shape: {
        coords: [10, 100, 10, 15, 15, 15, 15, 10],
        type: 'parking'
      }
    }
  },
  mounted () {
    if (this.data.locationStart) {
      const start = {
        position: {
          lat: this.data.locationStart.latitude,
          lng: this.data.locationStart.longitude
        },
        icon: require('@/assets/icons/start-pin-64.png')
      }
      this.markers.push(start)
    }
    if (this.data.locationEnd) {
      const end = {
        position: {
          lat: this.data.locationEnd.latitude,
          lng: this.data.locationEnd.longitude
        },
        icon: require('@/assets/icons/end-pin-64.png')
      }
      this.markers.push(end)
    }
  }
}
</script>

<style lang="css" scoped>
  .map-display {
    height: 300px;
    width: 100%;
  }
</style>
