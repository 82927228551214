<template>
  <div class="table-view">
    <slot name="table-cell"></slot>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.table-view {
  width: 100%;
  max-height: 100%;
  background-color: var(--forground);
}
</style>
