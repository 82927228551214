<template>
  <div class="timeline-cell">
    <div class="icon-state">
      <div class="ball"></div>
    </div>
    <div class="title">
      <p>Out</p>
    </div>
    <div class="time">
      <p>{{ format24HourSec(transaction.end) }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['transaction'],
  methods: {
    dateFormat (dateText) {
      return moment(dateText).format('LT')
    }
  }
}
</script>

<style lang="css" scoped>
.timeline-cell {
  background: var(--tgLight);
  margin-top: 4px;
  display: grid;
  grid-template-columns: 16px 50px 16px auto 16px 100px 32px;
  grid-template-rows: 16px auto 16px;
}

.timeline-cell > .icon-state {
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
}

.timeline-cell > .icon-state > .ball {
  background: var(--unapproved);
  width: 100%;
  padding-top: 100%;
  border-radius: 100%;
  align-self: center;
}

.timeline-cell > .title {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.timeline-cell > .time {
  width: 100%;
  height: 50px;
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
  text-align: right;
}

p {
  font-size: .9em;
  font-weight: 600;
}
</style>
