import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@aws-amplify/ui-vue'
import Amplify, { I18n } from 'aws-amplify'
import awsconfig from './aws-exports'
import VueSweetalert2 from 'vue-sweetalert2'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'
import { format24Hour, format24HourSec } from './common/dateformat'
// Map
import * as VueGoogleMaps from 'vue2-google-maps'

// Export to Exel
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueSweetalert2)

Amplify.configure(awsconfig)

Amplify.iI18n = I18n

Vue.prototype.$Amplify = Amplify

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA44xRps0ymUHC1uadcWBweldQjPwZ0PnA',
    libraries: 'visualization',
    // libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
    installComponents: false
  }
})

// Custom helper
Vue.mixin({
  methods: {
    format24Hour: format24Hour,
    format24HourSec: format24HourSec
  },
  computed: {
    canAccess () {
      return store.getters.canAccess
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
