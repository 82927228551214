<script>
import { Auth } from 'aws-amplify'
export default {
  mounted () {
    Auth.signOut().then(response => {
      this.$store.dispatch('signedOut')
      this.$router.push({ path: '/login' })
    }).catch(e => {
    })
  }
}
</script>
