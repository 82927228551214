import Vue from 'vue'
import Vuex from 'vuex'
import report from './report.module'
import axios from '../common/@axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userRole: '',
    signedIn: false
  },
  getters: {
    canAccess (state) {
      return state.userRole === 'owner' || state.userRole === 'superadmin'
    }
  },
  mutations: {
    'SET_USER_ROLE' (state, role) {
      state.userRole = role
    },
    'SET_USER_SINGIN_STATE' (state, status) {
      state.signedIn = status
    },
    'CLEAR_AUTH_DATA' (state) {
      state.userRole = ''
      state.signedIn = false
      window.location.reload()
    }
  },
  actions: {
    setUserRole (ctx, role) {
      ctx.commit('SET_USER_ROLE', role)
    },
    setUserSignedInState (ctx, status) {
      ctx.commit('SET_USER_SINGIN_STATE', status)
    },
    signedOut (ctx) {
      ctx.dispatch('CLEAR_AUTH_DATA')
    },
    unapproveTransaction (ctx, transactionId) {
      axios.put('/clock/unapprove/' + transactionId).then(response => {
        return response
      }).catch(e => {
        console.log(e)
      })
    },
    approveTransaction (ctx, transactionId) {
      axios.put('/clock/approve/' + transactionId).then(response => {
        return response
      }).catch(e => {
        console.log(e)
      })
    }
  },
  modules: {
    report
  }
})
