<template>
  <div class="camera-detail">
    <div class="left">
      <p>In</p>
      <img :src="data[0].clockInImageUrl" alt="">
    </div>
    <div class="right">
      <p>Out</p>
      <img :src="data[0].clockOutImageUrl" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style lang="scss" scoped>
.camera-detail {
  margin-top: 4px;
  display: flex;
  flex-flow: row;
}

.camera-detail > .left {
  height: 100%;
  flex: 1;

  p {
    padding: 0;
    margin: 0;
    padding-left: 4px;
    padding-top: 16px;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}

.camera-detail > .right {
  flex: 1;
  p {
    padding: 0;
    margin: 0;
    padding-left: 4px;
    padding-top: 16px;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
