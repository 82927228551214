import moment from 'moment'

export function format24Hour (date) {
  if (!date) return '-'
  return moment(date).format('HH:mm')
}

export function format24HourSec (date) {
  if (!date) return '-'
  return moment(date).format('HH:mm:ss')
}
