<template>
  <transition name="fade">>
    <is-loading v-if="!serverData"/>
    <the-layout :bgColor="'var(--forground)'" v-else>
      <filters slot="content" :filter.sync="filter" v-on:update="refreshData" :filterUnapprove.sync="filterUnapproveOnly" />
      <table-view slot="content">
        <div v-for="item in serverDataReversed" :key="item.udid" slot="table-cell">
          <keep-alive>
            <router-link
              tag="component"
              @click="showDetail(item)"
              :is="getComponent(item.status)"
              :data="item"
              >
            </router-link>
          </keep-alive>
        </div>

      </table-view>
    </the-layout>
  </transition>
</template>

<script>
import TheLayout from '@/components/TheLayout'
import TableView from '@/components/TableView'
import TableViewCellPending from './TableViewCellPending'
import TableViewCellInProgress from './TableViewCellInProgress'
import TableViewCellUnApproved from './TableViewCellUnApproved'
import TableViewCellApproved from './TableViewCellApproved'
import Filters from './Filters'
import IsLoading from '@/components/IsLoading'

export default {
  components: {
    TableView,
    TheLayout,
    TableViewCellPending,
    TableViewCellInProgress,
    TableViewCellApproved,
    TableViewCellUnApproved,
    Filters,
    IsLoading
  },
  mounted () {
    this.refreshData()
  },
  data () {
    return {
      /* eslint-disable */
      // serverData: {"transactions":[{"createAt":"2021-03-24T05:48:47.386Z","status":"IN_PROGRESS","firstname":"Jakkapan","sk":"2021-03-24T05:48:47.386Z","end":"","clockInImageUrl":"https://face-checkin.s3.ap-southeast-1.amazonaws.com/1616564925753","pk":"prikkeaw.triggersplus.com","staffId":"480e7132-967e-4c35-8639-4ab0f29527b5","transactionType":"CLOCKIN","start":"2021-03-24T05:48:47.386Z","udid":"4f29ab18-cdb6-4a09-9fdf-167faec3eaa1"},{"status":"PENDING","clockOutImageUrl":"https://face-checkin.s3.ap-southeast-1.amazonaws.com/1616575842731","clockInImageUrl":"https://face-checkin.s3.ap-southeast-1.amazonaws.com/1616575840677","transactionType":"CLOCKIN","udid":"bebfc457-fca0-4106-8fdf-6b82957d6121","createAt":"2021-03-24T08:50:41.789Z","parant":"a9256a8f-e666-4d45-9a79-dca60cf37e70","firstname":"Boom","sk":"2021-03-24T08:50:41.789Z","end":"2021-03-24T08:50:45.778Z","pk":"prikkeaw.triggersplus.com","staffId":"d8425d34-96e1-4fef-b51a-01ba31ea0607","start":"2021-03-24T08:50:41.789Z"},{"createAt":"2021-03-24T08:50:56.386Z","status":"IN_PROGRESS","firstname":"Boom","sk":"2021-03-24T08:50:56.386Z","end":"","clockInImageUrl":"https://face-checkin.s3.ap-southeast-1.amazonaws.com/1616575853887","pk":"prikkeaw.triggersplus.com","staffId":"d8425d34-96e1-4fef-b51a-01ba31ea0607","transactionType":"CLOCKIN","start":"2021-03-24T08:50:56.386Z","udid":"ecb21857-dd78-4063-bde5-61565cb86ab1"}],"count":3}
      serverData: '',
      filter: '',
      filterUnapproveOnly: false
      /* eslint-enable */
    }
  },
  methods: {
    getComponent (status) {
      if (status === 'IN_PROGRESS') {
        return TableViewCellInProgress
      } else if (status === 'PENDING') {
        return TableViewCellPending
      } else if (status === 'APPROVE') {
        return TableViewCellApproved
      } else if (status === 'UNAPPROVE') {
        return TableViewCellUnApproved
      }
    },
    refreshData () {
      const self = this
      this.$store.dispatch('fetchTransaction', this.filter).then(transactions => {
        self.serverData = transactions
      })
    },
    showDetail (transaction) {
      this.$router.push({ path: '/time-clock/' + transaction.udid })
    }
  },
  computed: {
    serverDataReversed () {
      if (!this.serverData) { return [] }

      const serverDataCopy = JSON.parse(JSON.stringify(this.serverData))
      if (this.filterUnapproveOnly) {
        serverDataCopy.transactions = serverDataCopy.transactions.filter(tran => {
          return tran.status === 'PENDING'
        })
      }

      const transaction = serverDataCopy || {}
      transaction.transactions = serverDataCopy.transactions || []
      return transaction.transactions.slice().reverse()
    }
  }
}
</script>

<style lang="css" scoped>

</style>
