<template>
  <div>
    <the-layout>
      <div slot='content'>
        <camera v-if="showCamera" :showCamera.sync="showCamera" @onSucces="onScanSuccess"></camera>
        <div class="staff-display" v-if="!staff">
          <button class="clock-button button" @click="showCamera = true">Clock</button>
        </div>
        <div class="staff-display" v-if="staff">
          <div class="content">
            <h1 class="title">Hi !! {{ staff.staff.name }}</h1>
            <h3 class="" v-if="staff.statusDisplay">You're {{ staff.statusDisplay }} now</h3>
            <h3 class="" v-if="staff.startAtDisplay">Start at {{ staff.startAtDisplay }}</h3>
            <h3 class="" v-if="staff.timeDiff">Total {{ staff.timeDiff }}</h3>
          </div>
          <button class="clockin-button button" v-if="staff.isOffWork && !staff.isOnBreak" @click="clockIn">Clock In</button>
          <button class="clockin-button button" v-if="!staff.isOffWork && !staff.isOnBreak" @click="startBreak">Break</button>
          <button class="clockin-button button" v-if="!staff.isOffWork && staff.isOnBreak" @click="endbreak">End break</button>
          <button class="clockin-button button" v-if="!staff.isOffWork && !staff.isOnBreak" @click="clockOut">Clock out</button>
        </div>
      </div>
    </the-layout>
  </div>
</template>

<script>
import axios from '../../../common/@axios'
import Camera from '@/components/Camera'
import TheLayout from '@/components/TheLayout'
export default {
  data () {
    return {
      showCamera: false,
      staff: ''
    }
  },
  components: {
    TheLayout,
    Camera
  },
  methods: {
    clockIn () {
      const params = {
        staff: this.staff.staff,
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/in', params).then(response => {
        alert('Welcome ' + this.staff.staff.name)
        this.backToNormalState()
      }).catch(e => {
        console.log(e)
        alert('clock in fail')
      })
    },
    clockOut () {
      const params = {
        staff: this.staff.staff,
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/clockout', params).then(response => {
        alert('Good bye :)')
        this.backToNormalState()
      }).catch(e => {
        console.log(e)
        alert(e.message)
      })
    },
    startBreak () {
      const params = {
        staff: this.staff.staff,
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/break', params).then(response => {
        alert('Have a good lunch !!')
        this.backToNormalState()
      }).catch(e => {
        console.log(e)
        alert('clock in fail')
      })
    },
    endbreak () {
      const params = {
        staff: this.staff.staff,
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/endbreak', params).then(response => {
        alert('Welcome back')
        this.backToNormalState()
      }).catch(e => {
        console.log(e)
        alert('clock in fail')
      })
    },
    onScanSuccess (staff) {
      this.showCamera = false
      this.staff = staff
    },
    backToNormalState () {
      this.staff = ''
    }
  }
}
</script>

<style lang="css" scoped>
.staff-display {
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding: 32px;
  align-items: center;
}

.clockin-button {
  flex: 1;
}

.button {
  min-height: 80px;
  max-height: 80px;
  height: 80px;
  width: 100%;
  border: none;
  background: var(--tgBlue);
  color: white;
  font-weight: 400;
  font-size: 2em;
}
</style>
