/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:97a4e13c-982c-4932-9157-6e3e75b4a2af",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_K2wcaz0yT",
    "aws_user_pools_web_client_id": "172ap3acjdvtiam48gk4ba00a6",
    "oauth": {}
};


export default awsmobile;
