/**
 *
 * @param {*} filters Example { from: '2020-01-01', to: '2020-01-31' }
 * @param {*} key Example: from
 * @returns 'from=2020-01-01'
 */
function mapToParamValue (filters, key) {
  const value = filters[key]
  return `${key}=${value}`
}

/**
 *
 * @param {*} queryString Example: ?message=Jakkapan&helo=Thongkam&
 * @returns ?message=Jakkapan&helo=Thongkam
 */
function cleanUpQueryString (queryString) {
  // ?message=Jakkapan&helo=Thongkam& => ?message=Jakkapan&helo=Thongkam
  return queryString.slice(0, -1)
}

/**
 *
 * @param {*} keyValueArray Example: [ 'message=Jakkapan', 'helo=Thongkam' ]
 * @returns ?message=Jakkapan&helo=Thongkam
 */
function parseToURLString (keyValueArray) {
  let queryString = keyValueArray.reduce((prev, con) => {
    return prev + con + '&'
  }, '?')

  // Clean up
  // ?message=Jakkapan&helo=Thongkam& => ?message=Jakkapan&helo=Thongkam
  queryString = cleanUpQueryString(queryString)

  return queryString
}

/**
 *
 * @param {*} filters Example: { from: '2020-01-01', to: '2020-01-31' }
 * @returns '?from=2020-01-01&to=2020-01-31'
 */
function toQueryStrings (filters = {}) {
  const keys = Object.keys(filters)

  const keyValue = keys.map(key => mapToParamValue(filters, key))

  const queryString = parseToURLString(keyValue)

  return queryString
}

module.exports = {
  toQueryStrings
}
