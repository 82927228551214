<template>
  <transition name="face">
  <is-loading v-if="!staffs"/>
  <the-layout v-else>
    <action-bar slot="content"></action-bar>
    <table-view slot="content" v-if="staffs">
      <TableViewCell
        v-for="staff in staffs"
        slot="table-cell"
        @click="$router.push({ path: `/staff/${staff.udid}` })"
        :key="staff.udid"
      ><p>{{ staff.name }}</p>
      </TableViewCell>
    </table-view>
  </the-layout>
  </transition>
</template>

<script>
import axios from '../../../../common/@axios'
import TableView from '@/components/TableView'
import TheLayout from '@/components/TheLayout'
import TableViewCell from './StaffTableViewCell'
import IsLoading from '@/components/IsLoading'
import ActionBar from './ActionBar'
export default {
  components: {
    TableView,
    TheLayout,
    TableViewCell,
    ActionBar,
    IsLoading
  },
  data () {
    return {
      staffs: ''
    }
  },
  mounted () {
    axios.get('/staff').then(response => {
      this.staffs = response.data.staff
    }).catch(e => {
      console.log(e)
    })
  }
}
</script>
<style lang="css" scoped>
  .table-view:nth-child(1) {
    margin-top: 10px;
  }
</style>
