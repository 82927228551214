<template>
  <the-layout class="container mt32">
    <div class="auth" slot="content">
    <div class="login">
      <h2>PHACENIX Register</h2>
      <form class="login__from" @submit="submitRegister">
        <div class="form-group">
          <label for="exampleInputEmail1">Telephone</label>
          <input type="tel" ref="phone" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="096 666 6666" v-model="telephone" minlength="10" maxlength="10">
          <small v-if="errorPhone" id="emailHelp" class="form-text text-danger">{{ errorPhone }}</small>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input ref="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="john@phacenix.com" v-model="email">
          <small v-if="errorEmail" id="emailHelp" class="form-text text-danger">{{ errorEmail }}</small>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Name</label>
          <input ref="name" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="John" v-model="name">
          <small v-if="errorName" id="emailHelp" class="form-text text-danger">{{ errorName }}</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input ref="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" minlength="8" v-model="password">
          <small v-if="errorPassword" id="emailHelp" class="form-text text-danger">{{ errorPassword }}</small>
        </div>
        <small id="loginHelp" class="form-text text-danger text-center" v-if="errorResponse">{{ errorResponse }}</small>
        <div class="login__action">
          <router-link :tag="a" to="/login">Back to login</router-link>
        </div>
        <hr>
        <button type="submit" class="btn btn-tgblue btn-lg">Register</button>
      </form>
    </div>
    </div>
  </the-layout>
</template>

<script>
import { Auth } from 'aws-amplify'
import TheLayout from '@/components/TheLayout'
export default {
  components: {
    TheLayout
  },
  data () {
    return {
      telephone: '',
      password: '',
      email: '',
      name: '',
      userRole: 'staff',
      error: [],
      errorResponse: '',
      errorEmail: '',
      errorPhone: '',
      errorName: '',
      errorPassword: ''
    }
  },
  methods: {
    async submitRegister (e) {
      e.preventDefault()
      let phone = this.telephone
      const password = this.password
      const email = this.email
      const name = this.name

      this.errorPhone = ''
      this.errorEmail = ''
      this.errorName = ''
      this.errorPassword = ''

      if (phone.length !== 10) {
        this.errorPhone = 'Phone number should more than 10 characters'
        this.$refs.phone.focus()
        return
      }

      if (!email) {
        this.errorEmail = 'Email is required'
        return this.$refs.email.focus()
      }
      if (!name) {
        this.errorName = 'name is required'
        this.$refs.name.focus()
        return
      }
      if (!password) {
        if (password.length < 8) {
          this.errorPassword = 'Password should more than 8 characters'
          this.$refs.password.focus()
          return
        }
        this.errorPassword = 'Password should more than 8 characters'
        this.$refs.password.focus()
        return
      }

      phone = phone.replace('0', '+66')

      Auth.signUp({
        username: phone,
        phone,
        password,
        attributes: {
          email,
          'custom:name': name,
          'custom:site': window.origin.split('//')[1]
        }
      }).then(response => {
        this.$swal.fire('Success', 'Please confirm your phone number', 'success')
        this.$router.push({ name: 'ConfirmOTP', params: { phone: this.telephone } })
      }).catch(e => {
        this.$swal.fire(`${e.message}`, 'Please try again', 'error')
        if (e.code === 'UsernameExistsException') {
          this.$router.push({ path: '/login' })
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
  .login {
    padding: 16px;
  }
  .login__from {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding-top: 16px;
  }
  .btn-tgblue {
    background: var(--tgBlue);
    color: white;
  }
  #loginHelp {
    font-size: 1.1em;
    margin: 10px;
  }
</style>
