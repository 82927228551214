<template>
  <the-layout>
    <div class="report-wraper" slot="content">
      <Filters>
        <download-excel
        class="btn btn-secondary pulled-right btn-sm"
        :data="outputData"
        :name="getFilenameForExal()"
        >
        Download Excel
      </download-excel>
      </Filters>
      <b-table responsive striped hover
      :per-page="perPage"
      :current-page="currentPage"
      id="report-table"
      :items="outputData" v-if="outputData"></b-table>
      <div v-else><p>No Approved transaction</p></div>
      <b-pagination
        v-model="currentPage"
        :total-rows="outputData.length"
        :per-page="perPage"
        align="fill"
        aria-controls="report-table"
      ></b-pagination>
    </div>
  </the-layout>
</template>
<script>
import Filters from './Filters'
import TheLayout from '@/components/TheLayout'
import moment from 'moment'
export default {
  components: {
    Filters,
    TheLayout
  },
  data () {
    return {
      perPage: 10,
      currentPage: 1,
      serverData: '',
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' }
      ],
      fields: [
        {
          key: 'firstname',
          title: 'Staff Name',
          format: 'text',
          default: ''
        },
        {
          key: 'status',
          title: 'Status',
          format: 'text',
          default: ''
        },
        {
          key: 'createAt',
          title: 'In date',
          format: 'date',
          default: '-'
        },
        {
          key: 'start',
          title: 'In time',
          format: 'time',
          default: '-'
        },
        {
          key: 'end',
          title: 'Out Date',
          format: 'date',
          default: '-'
        },
        {
          key: 'end',
          title: 'Out time',
          format: 'time',
          default: '-'
        },
        {
          key: 'timeDiff',
          title: 'Duration',
          format: 'text',
          default: '-'
        },
        {
          key: 'breakTimeDiff',
          title: 'Total Break',
          format: 'text',
          default: '-'
        },
        {
          key: 'locationStart.address',
          title: 'In at',
          format: 'text',
          default: '-'
        },
        {
          key: 'locationEnd.address',
          title: 'Out at',
          format: 'text',
          default: '-'
        }
      ]
    }
  },
  mounted () {
    this.fetchReport()
  },
  methods: {
    fetchReport () {
      const self = this
      const filter = {
        from: '2021-04-01',
        to: '2021-07-30',
        status: 'APPROVE'
      }
      this.$store.dispatch('fetchTransaction', filter).then(transactions => {
        self.serverData = transactions
      }).catch(e => {
        alert('fail')
      })
    },
    format (field, data) {
      if (field.format === 'text') {
        return data
      } else if (field.format === 'date') {
        if (!data) return field.default
        return moment(data).format('ll')
      } else if (field.format === 'time') {
        if (!data) return field.default
        return this.format24HourSec(data)
      }
    },
    getFilenameForExal () {
      const timestamp = moment(new Date()).format('YYYY-MM-DD__HH:MM:SS')
      return `PHACENIX_${timestamp}.xls`
    }
  },
  computed: {
    outputData () {
      if (this.serverData === '') return []
      const self = this
      const output = this.serverData.transactions.map(function (transaction) {
        const outputRow = {}

        // Preparing function
        function isHasDotInKey (keyText) { return String(keyText).indexOf('.') !== -1 }
        /**
        * Non-Subkey
        * EX: transaction.name
        */
        function row (field, transaction) { outputRow[field.title] = self.format(field, transaction[field.key]) }
        /**
        * Subkey
        * EX: transaction.locationStart.address
        */
        function rowWithSubKey (field, transaction) {
          const key = field.key.split('.')[0]
          const subkey = field.key.split('.')[1]

          // Prepair function
          function setDefaultForColumn () { outputRow[field.title] = self.format(field, field.default) }
          function checkIsHasSubKey () {
            if (!transaction[key]) { return false }
            if (!transaction[key][subkey]) { return false }
            return true
          }

          // main logic
          if (checkIsHasSubKey()) {
            outputRow[field.title] = self.format(field, transaction[key][subkey])
          } else {
            setDefaultForColumn()
          }
        }

        // Main Process
        self.fields.forEach(function (field) {
          if (isHasDotInKey(field.key)) {
            rowWithSubKey(field, transaction)
          } else {
            row(field, transaction)
          }
        })

        return outputRow
      })

      return output
    }
  }
}
</script>
