<script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
export default {
  data () {
    return {
      userRole: 'staff',
      signedIn: false
    }
  },
  async beforeCreate () {
    try {
      await Auth.currentAuthenticatedUser()
      this.userRole = 'staff'
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }

    try {
      const user = await Auth.currentAuthenticatedUser()
      const userGroups = user.signInUserSession.accessToken.payload['cognito:groups']
      if (userGroups.indexOf('owner') !== -1) {
        this.userRole = 'owner'
      }
    } catch (err) {
      this.userRole = 'staff'
    }

    this.$store.dispatch('setUserRole', this.userRole)

    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.$router.push({ path: '/' })
      } else {
        this.signedIn = false
        this.$store.dispatch('signedOut')
        window.location.reload()
      }
    })
  }
}
</script>
