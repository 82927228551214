<template>
  <div>
    <the-layout>
      <section slot="content" class="current_data_box center-text">
        <div class="location" @click="getLocation">
          {{ currentAddress }}
        </div>
        <div class="timeNow">
          {{ timeNow }}
        </div>
        <div class="dateNow">
          {{ dateNow }}
        </div>
      </section>
      <section slot='content'>
        <camera v-if="showCamera" :showCamera.sync="showCamera" @onSucces="onScanSuccess"></camera>
        <div class="staff-display" v-if="!staff">
          <div class="buttons-action">
            <button class="clockin-button button" @click="showCamera = true">
              <i class="far fa-smile-beam large"></i>
              <br>
            Face Scan
            </button>
          </div>
          <!-- <p v-else>Please enable location.</p> -->
        </div>
        <div class="staff-display" v-if="staff">
          <div class="content has-text-center">
            <h6 class="title">Hi !! {{ staff.staff.name }}</h6>
            <!--
            <h3 class="" v-if="staff.statusDisplay">You're {{ staff.statusDisplay }} now</h3>
            <h3 class="" v-if="staff.startAtDisplay">Start at {{ staff.startAtDisplay }}</h3>
            <h3 class="" v-if="staff.timeDiff">Total {{ staff.timeDiff }}</h3>
            -->
          </div>
          <div class="buttons-action">
            <button class="clockin-button button" :disabled="!(staff.isOffWork && !staff.isOnBreak)" @click="clockIn"><i class="fas fa-sign-in-alt"></i><br> In</button>
            <button class="clockin-button button button-warning" v-if="(!staff.isOffWork && !staff.isOnBreak) || staff.isOffWork" :disabled="!(!staff.isOffWork && !staff.isOnBreak)" @click="startBreak"><i class="fas fa-mug-hot footer-item-icon"></i><br> Break</button>
            <button class="clockin-button button" v-if="!staff.isOffWork && staff.isOnBreak" :disabled="!(!staff.isOffWork && staff.isOnBreak)" @click="endbreak"><i class="fas fa-mug-hot footer-item-icon"></i> <br>End break</button>
            <button class="clockin-button button button-danger" :disabled="!(!staff.isOffWork && !staff.isOnBreak)" @click="clockOut"><i class="fas fa-sign-out-alt footer-item-icon"></i><br> Out</button>
          </div>
        </div>
      </section>

      <section slot="content">
        <clock-time-line v-if="staff && staff.transactions" :transactions="staff.transactions">
        </clock-time-line>
      </section>
    </the-layout>
  </div>
</template>

<script>
import axios from '../../../common/@axios'
import Camera from '@/components/Camera'
import TheLayout from '@/components/TheLayout'
import ClockTimeLine from './ClockTimeLine'
import moment from 'moment'
export default {
  components: {
    TheLayout,
    Camera,
    ClockTimeLine
  },
  data () {
    return {
      showCamera: false,
      staff: '',
      now: '',
      currentAddress: 'LOADING LOCATION...',
      latitude: '',
      longitude: ''
    }
  },
  methods: {
    clockIn () {
      const params = {
        staff: this.staff.staff,
        location: {
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.currentAddress
        },
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/in', params).then(response => {
        // alert('Welcome ' + this.staff.staff.name)
        this.$swal.fire(
          `Welcome ${this.staff.staff.name}`,
          'Clock in success',
          'success'
        )
        this.backToNormalState()
      }).catch(e => {
        console.log(e)
        this.$swal.fire(
          `${e.response.message}`,
          'Please try again.',
          'danger'
        )
      })
    },
    clockOut () {
      const params = {
        staff: this.staff.staff,
        location: {
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.currentAddress
        },
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/clockout', params).then(response => {
        this.$swal.fire(
          'Good bye :)',
          'Clock out success',
          'success'
        )
        this.backToNormalState()
      }).catch(e => {
        this.$swal.fire(
          `${e.response.message}`,
          'Please try again.',
          'danger'
        )
      })
    },
    startBreak () {
      const params = {
        staff: this.staff.staff,
        location: {
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.currentAddress
        },
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/break', params).then(response => {
        this.$swal.fire(
          'Enjoy your break!',
          '',
          'success'
        )
        this.backToNormalState()
      }).catch(e => {
        console.log(e)
        alert('clock in fail')
      })
    },
    endbreak () {
      const params = {
        staff: this.staff.staff,
        location: {
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.currentAddress
        },
        imageUrl: this.staff.imageUrl
      }
      axios.post('/clock/endbreak', params).then(response => {
        this.$swal.fire(
          'Welcome back',
          '',
          'success'
        )
        this.backToNormalState()
      }).catch(e => {
        this.$swal.fire(
          `${e.response.message}`,
          '',
          'danger'
        )
      })
    },
    onScanSuccess (staff) {
      this.showCamera = false
      this.staff = staff
    },
    backToNormalState () {
      this.staff = ''
    },
    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(this.showPosition)
      } else {
        this.$refs.x.innerHTML = 'Geolocation is not supported by this browser.'
      }
    },
    showPosition (position) {
      this.showAddressLatLng(position.coords.latitude, position.coords.longitude)
    },
    async showAddressLatLng (latitude, longitude) {
      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA44xRps0ymUHC1uadcWBweldQjPwZ0PnA`)
        console.log(response)
        const fullAddress = response.data.results[0].formatted_address
        this.currentAddress = fullAddress
        this.latitude = latitude
        this.longitude = longitude
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted () {
    setInterval(() => {
      this.now = new Date()
    }, 1000)
    this.getLocation()
  },
  computed: {
    timeNow () {
      if (!this.now) { return 'LOADING CLOCK...' }
      return this.format24HourSec(this.now)
    },
    dateNow () {
      if (!this.now) { return '' }
      return `${moment(this.now).format('ddd')} ${moment(this.now).format('D')} ${moment(this.now).format('MMM')} ${moment(this.now).format('YYYY')}`
    }
  }
}
</script>

<style lang="css" scoped>
.staff-display {
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding: 32px;
  align-items: center;
}

.button {
  width: 100%;
  min-width: 100px;
  min-height: 80px;
  border: none;
  background: var(--tgDarkBlue);
  color: white;
  font-weight: 600;
  font-size: 1em;
  border-radius: 8px;
}

.location {
  width: 100%;
  font-size: .6em;
}
.timeNow {
  font-size: 2.5em;
  font-weight: 600;
}
.center-text {
  text-align: center;
}
.dateNow {
  font-weight: 600;
  color: rgba(1, 1, 1, .6)
}
.current_data_box {
  padding: 16px;
}

.button:disabled {
  cursor:not-allowed;
  color: gray;
  background: var(--tgBlueLight);
}

.buttons-action {
  display: flex;
  flex-flow: row;
  max-width: 400px;
}

.clockin-button {
  flex: 1;
  margin-left: 10px
}

.clockin-button:nth-child(1) {
  flex: 1;
  margin-left: 0px
}

.button-danger {
  background: var(--unapproved);
}

.button-warning {
  background: var(--pending);
}

.has-text-center {
  text-align: center;
}

i.large {
  font-size: 2em;
}
</style>
