<template>
  <button>
    Approve
  </button>
</template>
<script>
export default {
  props: ['title']
}
</script>

<style lang="css" scoped>
button {
  background: var(--success);
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 43px;
  font-size: .8em;
}
</style>
