<template>
  <the-layout class="container mt32">
    <div class="auth" slot="content">
    <div class="login">
      <h2>Confirm Phone number</h2>
      <form class="login__from" @submit="submitConfirmPhoneNumber">
        <div class="form-group">
          <label for="exampleInputEmail1">Telephone</label>
          <input type="tel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="096 666 6666" v-model="telephone" minlength="10" maxlength="10">
        </div>
        <button type="button" class="btn btn-secondary" v-if="!sendedOTP" @click="sendOTP">Send OTP</button>
        <div class="after_send_top login__from" v-if="sendedOTP">
          <div class="form-group">
            <label for="exampleInputPassword1">OTP</label>
            <input ref="otp" type="tel" class="form-control" id="exampleInputPassword1" placeholder="xxxxxx" v-model="otp">
          </div>
          <small id="loginHelp" class="form-text text-danger text-center" v-if="errorResponse">{{ errorResponse }}</small>
          <button type="submit" class="btn btn-tgblue">Submit</button>
          <hr>
          <button type="button" class="btn" v-if="sendedOTP" @click="sendOTP">Resend OTP</button>
        </div>
        <hr>
        <div class="login__action">
          <router-link :tag="a" to="/login">Back to login</router-link>
        </div>
      </form>
    </div>
    </div>
  </the-layout>
</template>

<script>
import { Auth } from 'aws-amplify'
import TheLayout from '@/components/TheLayout'
export default {
  props: {
    phone: {
      default: ''
    }
  },
  components: {
    TheLayout
  },
  mounted () {
    this.telephone = this.phone
    if (this.telephone !== '') {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.otp.focus()
        }, 500)
      })
    }
  },
  data () {
    return {
      telephone: '',
      password: '',
      userRole: 'staff',
      error: [],
      errorResponse: '',
      sendedOTP: true,
      otp: ''
    }
  },
  methods: {
    sendOTP () {
      let phone = this.telephone

      if (phone.length !== 10) {
        this.$swal.fire('Invalid phone number', 'Please try again', 'error')
        return
      }

      phone = phone.replace('0', '+66')
      Auth.resendSignUp(phone).then(response => {
        this.sendedOTP = true
      }).catch(e => {
        this.$swal.fire(`${e.message}`, 'Please try again', 'error')
      })
    },
    async submitConfirmPhoneNumber (e) {
      let phone = this.telephone
      const otp = this.otp

      if (phone.length !== 10) {
        return
      }

      phone = phone.replace('0', '+66')

      Auth.confirmSignUp(phone, otp).then(response => {
        this.$swal.fire('Success', 'Please login again', 'success')
        this.$router.push({ path: '/login' })
      }).catch(e => {
        this.$swal.fire(`${e.message}`, 'Please try again', 'error')
      })

      e.preventDefault()
    }
  }
}
</script>

<style lang="css" scoped>
  .login {
    padding: 16px;
  }
  .login__from {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding-top: 16px;
  }
  .btn-tgblue {
    background: var(--tgBlue);
    color: white;
  }
  #loginHelp {
    font-size: 1.1em;
    margin: 10px;
  }
</style>
