<template>
  <the-layout>
    <b-container fluid slot='content'>
    <camera-component @onCapture="setFile" v-if="showCamera" :showCamera.sync="showCamera"></camera-component>
    <div class="staff-detail">
      <div class="image" ref="profile">
        <div class="image-edit" @click="showCamera = true">
          แก้ไข
        </div>
      </div>
      or
      <br>
      <input type="file" @change="onSelectedFile" ref="selectFile">
      <hr />
        <b-row class="my-2">
          <b-col sm="12">
            <b-form-input :id="`type-${type}`" :type="'text'" :placeholder="'Staff Name'" v-model="staffName"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-2">
          <b-col sm="12">
            <b-form-input :id="`type-${type}`" :type="'tel'" :placeholder="'Staff password'" v-model="staffPassword"></b-form-input>
          </b-col>
        </b-row>
        <hr />
        <div class="page-action">
          <b-button size="lg" >Cancel</b-button>
          <b-button size="lg" class="primary" @click="saveNewStaff">Save</b-button>
        </div>
    </div>
    </b-container>
  </the-layout>
</template>

<script>
import axios from '../../../../common/@axios'
import TheLayout from '@/components/TheLayout'
import CameraComponent from '@/components/CameraComponent'
export default {
  components: {
    TheLayout,
    CameraComponent
  },
  data () {
    return {
      staffEditing: '',
      showCamera: false,
      file: '',
      staffName: '',
      staffPassword: '',
      image: '',
      imageUrl: ''
    }
  },
  mounted () {},
  methods: {
    setFile (file) {
      this.stopCamera()
      this.file = file.fileData
      this.$refs.profile.style.backgroundImage = `url('${file.path}')`
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?)/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime })
    },
    stopCamera () {
      this.showCamera = false
    },
    async saveNewStaff () {
      try {
        if (!this.file) {
          this.$swal.fire(
            'Staff image is required!!',
            'Please try again.',
            'warning'
          )
          return
        }
        if (!this.staffName) {
          this.$swal.fire(
            'Staff name is required!!',
            'Please try again.',
            'warning'
          )
          return
        }
        if (!this.staffPassword) {
          this.$swal.fire(
            'Staff password is required!!',
            'Please try again.',
            'warning'
          )
          return
        }
        var formData = new FormData()
        formData.append('sampleFile', this.file, this.file.name)
        formData.append('name', this.staffName)
        formData.append('password', this.staffPassword)
        axios.post('/staff', formData).then(response => {
          this.$router.push('/staff')
        }).catch(e => {
          console.error(e.response.data.message)
          this.$swal.fire(
            `${e.response.data.message}!!`,
            'Please try again. here',
            'warning'
          )
        })
      } catch (e) {
        // console.error(e.response.data.message)
        this.$swal.fire(
          `${e.response.data.message}!!`,
          'Please try again.',
          'warning'
        )
      }
    },
    async onSelectedFile (e) {
      const files = e.target.files
      const filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
        const data = this.dataURLtoFile(this.imageUrl, filename)
        this.file = data
        // console.log(this.file.name)
        this.$refs.profile.style.backgroundImage = `url('${fileReader.result}')`
      })
      fileReader.readAsDataURL(files[0])
    }
  }
}
</script>

<style lang="css" scoped>
  .staff-detail {
    padding-top: 16px;
    padding-bottom: 50px;
  }

  .staff-detail > .image {
    width: 256px;
    height: 320px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: var(--dark);
    background-image: url('https://phacenix-prod.s3.ap-southeast-1.amazonaws.com/1616861222611?AWSAccessKeyId=ASIA6CDXBJO2ONXVAH7S&Expires=1617080089&Signature=MfUEBm96pVmox0LqFtityEBEJF8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECUaDmFwLXNvdXRoZWFzdC0xIkcwRQIhAJtBzKk3Hi9%2BlWB0PeIoCo9QzQVb%2BMxtxY7VR%2BEBbo7qAiAETIjJnH2hLHOzDesdcHVyu7l5xnZ%2BAJEngnBexqF4LSrSAQhuEAAaDDk2NjYxNzI4NzYwNCIMbM8fmp0Tft%2FNF7D1Kq8Bc7HHTiiP0v5FVYzy9Mhx%2Ft0lejILdOQD4lPkxqiGJ8OYTVDZafsj%2Bj8t%2F7C20Klw0Hi%2BJg9ufsxdTccN95SoJx%2BAP7V1en6PKwATNBahKklRrbBQeO9BYIlLIQjCm4VumYS3cFnyCOe133hzjb1PMxKML6IGVgegn5mYnunyIz5Bt5kGgMqIXhL4ukK9PX%2FoEloVnfGXaEvcYagRZ8D%2Bq5qiOToz9O6w9xXDEXmosDDk0oqDBjrgAZwr%2FxBOxbyOLGlYIVgkYEoeSo58M4WSK1%2BqwRvuZI6KlQh%2FUJv7B6VH%2BainpaEr7QwL90xNdKcdXM1Nk6mDPs6gJgCUh3SUrc7cLWrlpSZwQmq9GkMpiG54%2FrBMlu5RaU18s%2BT6jXOoxRIjLWc6HJaXfYA2w8NHrrDitGXGvlMRPP7DmxtjmW1d1l0NeGbDAjaNdW9cwETxQG%2B%2FFbG4PUPOfdkhi3DApauHrAT9Db7N9hLZdO2%2Bme5aSmDwrNcZQjO%2BqRK9VLnWdDEkFsaQvlXr7XqkvRzi3h4ieF9VB0Eo');
  }

  .image-edit {
    background: var(--tgBlue);
    font-weight: 400;
    font-size: 1.2em;
    line-height: 2em;
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
  }

  .primary {
    background-color: var(--tgBlue);
  }

  .page-action {
    display: flex;
    justify-content: space-between;
  }
</style>
