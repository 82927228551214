<template>
  <the-layout class="container mt32">
    <div class="auth" slot="content">
    <div class="login">
      <h2>Forgot your password</h2>
      <form class="login__from" @submit="submitForgotPassword">
        <div class="form-group">
          <label for="exampleInputEmail1">Telephone</label>
          <input type="tel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="086 xxx xxx" v-model="telephone" minlength="10" maxlength="10">
        </div>
        <button type="button" class="btn btn-secondary" v-if="!sendedOTP" @click="sendOTP">Send OTP</button>
        <div class="after_send_top login__from" v-if="sendedOTP">
          <div class="form-group">
            <label for="exampleInputPassword1">OTP</label>
            <input ref="otp" type="tel" class="form-control" id="exampleInputPassword1" placeholder="xxxxxx" v-model="otp">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">New Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="xxxxxx" v-model="password">
          </div>
          <small id="loginHelp" class="form-text text-danger text-center" v-if="errorResponse">{{ errorResponse }}</small>
          <button type="submit" class="btn btn-tgblue">Submit</button>
          <hr>
          <button type="button" class="btn" v-if="sendedOTP" @click="sendOTP">Resend OTP</button>
        </div>
        <hr>
        <div class="login__action">
          <router-link :tag="a" to="/login">Back to login</router-link>
        </div>
      </form>
    </div>
    </div>
  </the-layout>
</template>

<script>
import { Auth } from 'aws-amplify'
import TheLayout from '@/components/TheLayout'
export default {
  components: {
    TheLayout
  },
  data () {
    return {
      telephone: '',
      password: '',
      userRole: 'staff',
      error: [],
      errorResponse: '',
      sendedOTP: false,
      otp: ''
    }
  },
  methods: {
    sendOTP () {
      let phone = this.telephone

      if (phone.length !== 10) {
        this.$swal.fire('Invalid phone number', 'Please try again', 'error')
        return
      }

      phone = phone.replace('0', '+66')
      Auth.forgotPassword(phone).then(response => {
        this.sendedOTP = true
        this.$nextTick(() => {
          this.$refs.otp.focus()
        })
      }).catch(e => {
        this.$swal.fire(`${e.message}`, 'Please try again', 'error')
      })
    },
    async submitForgotPassword () {
      let phone = this.telephone
      const password = this.password
      const otp = this.otp

      if (phone.length !== 10) {
        return
      }

      phone = phone.replace('0', '+66')

      Auth.forgotPasswordSubmit(phone, otp, password).then(response => {
        this.$swal.fire('Success', 'Please login again', 'is-success')
        this.$router.push({ path: '/login' })
      }).catch(e => {
        this.$swal.fire(`${e.message}`, 'Please try again', 'error')
      })
    },
    async submitLogin (e) {
      let phone = this.telephone
      const password = this.password

      if (phone.length !== 10) {
        return
      }

      phone = phone.replace('0', '+66')

      Auth.signIn(phone, password).then(async (response) => {
        this.signedIn = true

        try {
          const user = await Auth.currentAuthenticatedUser()
          const userGroups = user.signInUserSession.accessToken.payload['cognito:groups']

          if (userGroups.indexOf('owner') !== -1) { this.userRole = 'owner' }
          this.$store.dispatch('setUserRole', this.userRole)
        } catch (err) {
          this.userRole = 'staff'
        }

        this.$router.push({ path: '/' })
      }).catch(e => {
        if (e.code === 'NotAuthorizedException' || e.code === 'UserNotFoundException') {
          this.errorResponse = 'Incorrect username or password.'
        } else {
          this.errorResponse = e.message
        }
      })

      e.preventDefault()
    }
  }
}
</script>

<style lang="css" scoped>
  .login {
    padding: 16px;
  }
  .login__from {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding-top: 16px;
  }
  .btn-tgblue {
    background: var(--tgBlue);
    color: white;
  }
  #loginHelp {
    font-size: 1.1em;
    margin: 10px;
  }
</style>
