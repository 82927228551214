<template>
  <the-layout>
    <table-view slot="content">
      <TableViewCell slot="table-cell" @click="$router.push({ path: '/staff' })" :iconClass="'fas fa-users'" v-if="canAccess">Staff</TableViewCell>
      <TableViewCell slot="table-cell" @click="$router.push({ path: '/report' })" :iconClass="'far fa-chart-bar'" v-if="canAccess">Report</TableViewCell>
      <div class="container mt-20" slot="table-cell">
        <version />
      </div>
      <div class="container mt-80" slot="table-cell">
        <amplify-sign-out class="btn btn-block"></amplify-sign-out>
      </div>
    </table-view>
  </the-layout>
</template>

<script>
import TableView from '@/components/TableView'
import TheLayout from '@/components/TheLayout'
import TableViewCell from './MoreTableViewCell'
import Version from '@/components/Version'
export default {
  components: {
    TableView,
    TheLayout,
    TableViewCell,
    Version
  }
}
</script>
<style lang="css" scoped>
  .table-view:nth-child(1) {
    padding-top: 16px;
  }
  .mt-80 {
    margin-top: 80px;
  }
  .mt-20 {
    margin-top: 20px;
  }
</style>
