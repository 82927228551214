<template>
  <div class="clock-detail">
    <div class="clock-item clock-detail-in">
      <p>In</p>
      <b>{{ format24HourSec(data.start) }}</b>
      <p>{{ dateTimeDate(data.start) }}</p>
    </div>
    <div class="clock-item clock-detail-out">
      <p>Out</p>
      <b>{{ format24HourSec(data.end) }}</b>
      <p>{{ dateTimeDate(data.end) }}</p>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['data'],
  methods: {
    dateTimeDate (dateString) {
      if (!dateString) { return '-' }
      return moment(dateString).format('ll')
    }
  }
}
</script>

<style lang="css" scoped>
  .clock-detail {
    width: 100%;
    background: var(--tgLight);
    display: flex;
    flex-flow: row;
    color: var(--secondary);
  }

  .clock-detail-in {
    flex: 1;
    padding-left: 16px;
  }

  .clock-detail-out {
    flex: 1;
  }

  .clock-item  {
      padding-left: 16px;
  }
  .clock-item > b {
    font-size: 2em;
    font-weight: 500;
    line-height: .8em;
    color: var(--secondary);
  }
</style>
