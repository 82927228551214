<template>
<div>
  <transition name="fade">
    <is-loading class="middle" v-if="isLoading"/>
    <the-layout class="flex" v-else>
      <clock-duration :data="clockTransaction" slot="content"></clock-duration>
      <transaction-detail :data="clockTransaction" slot="content" :is="getComponent(clockTransaction.status)"></transaction-detail>
      <clock-detail class="m-t-4" :data="clockTransaction" slot="content"></clock-detail>
      <clock-timeline :data="transactionData" slot="content"></clock-timeline>
      <camera-detail :data="transactionData" slot="content"></camera-detail>
      <clock-map slot="content" :data="clockTransaction"></clock-map>
      <div slot="content" class="approve__button__box container" v-if="transactionData[0].status != 'IN_PROGRESS'">
        <div class="row">
          <div class="col-6">
            <button class="btn btn-danger btn-fullwidth col-12" @click="unapprove(transactionData[0].udid)">Un Approve</button>
          </div>
          <div class="col-6">
            <button class="btn btn-primary btn-fullwidth col-12" @click="approve(transactionData[0].udid)">Approve</button>
          </div>
        </div>
      </div>
    </the-layout>
  </transition>
</div>
</template>
<script>
import TheLayout from '@/components/TheLayout'
import IsLoading from '@/components/IsLoading'
import ClockDuration from './ClockDuration'
import StaffDetail from './StaffDetail'
import ClockDetail from './ClockDetail'
import ClockTimeline from './ClockTimeline'
import TableViewCellApproved from './TableViewCellApproved'
import TableViewCellPending from './TableViewCellPending'
import TableViewCellInProgress from './TableViewCellInProgress'
import TableViewCellUnApproved from './TableViewCellUnApproved'
import CameraDetail from './CameraDetail'
import ClockMap from './ClockMap'
export default {
  components: {
    TheLayout,
    IsLoading,
    ClockDuration,
    StaffDetail,
    ClockDetail,
    ClockTimeline,
    TableViewCellApproved,
    TableViewCellPending,
    TableViewCellInProgress,
    TableViewCellUnApproved,
    CameraDetail,
    ClockMap
  },
  data () {
    return {
      isLoading: false,
      transactionData: ''
    }
  },
  mounted () {
    if (!this.$route.params.transactionId) {
      this.$router.push('/')
    }
    const { transactionId } = this.$route.params
    this.fetchData(transactionId)
  },
  methods: {
    fetchData (transactionId) {
      this.isLoading = true
      this.$store.dispatch('fetchTransactionWithId', transactionId).then(response => {
        this.isLoading = false
        this.transactionData = response
      }).catch(e => {
        this.isLoading = false
        this.throwError()
      })
    },
    unapprove (transactionId) {
      const self = this
      this.$store.dispatch('unapproveTransaction', transactionId).then(response => {
        this.isLoading = false
        setTimeout(function () {
          self.fetchData(transactionId)
        }, 500)
      }).catch(e => {
        this.isLoading = false
        this.throwError()
      })
    },
    approve (transactionId) {
      const self = this
      this.$store.dispatch('approveTransaction', transactionId).then(response => {
        this.isLoading = false
        setTimeout(function () {
          self.fetchData(transactionId)
        }, 500)
      }).catch(e => {
        this.isLoading = false
        this.throwError()
      })
    },
    throwError (e) {
      alert(e)
    },
    getComponent (status) {
      if (status === 'IN_PROGRESS') {
        return TableViewCellInProgress
      } else if (status === 'PENDING') {
        return TableViewCellPending
      } else if (status === 'APPROVE') {
        return TableViewCellApproved
      } else if (status === 'UNAPPROVE') {
        return TableViewCellUnApproved
      }
    }
  },
  computed: {
    clockTransaction () {
      return this.transactionData.filter(tran => {
        return tran.transactionType === 'CLOCKIN'
      })[0]
    }
  }
}
</script>

<style lang="css" scoped>
  .flex {
    display: flex;
    flex-flow: column;
    gap: 4px;
    color: var(--secondary);
  }

  .approve__button__box {
    padding: 32px;
  }

  .approve__button__box .row {
    min-width: 100%;
  }

  button.btn-primary {
    padding: 16px;
    background: var(--approved);
    border: none;
  }

  button.btn-danger {
    padding: 16px;
    background: var(--unapproved);
    border: none;
  }

  .m-t-4 {
    margin-top: 4px;
  }
</style>
