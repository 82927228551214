<template>
  <div id="camera">
    <WebCam
      id="camera-screen"
      ref='webcam'
      height="100%"
      width="100%"
      :device-id='deviceId'
      @started='onStarted'
      @stopped='onStopped'
      @error='onError'
      @cameras='onCameras'
      @camera-change='onCameraChange'
    ></WebCam>
    <div id="action-group">
      <button id="close-button" @click="$emit('update:showCamera', false)"><i class="far fa-times-circle"></i></button>
      <button id="capture-button" @click="onCapture"></button>
      <button id="switch-button" @click="switchCamera"><i class="fas fa-random"></i></button>
    </div>
  </div>
</template>

<script>
import { WebCam } from 'vue-web-cam'
export default {
  props: ['showCamera'],
  components: {
    WebCam
  },
  data () {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: []
    }
  },
  methods: {
    switchCamera () {
      const [first, ...tail] = this.devices
      if (this.deviceId === first.deviceId) {
        if (tail.length > 0) {
          this.deviceId = tail[0].deviceId
        }
      } else {
        this.deviceId = first.deviceId
      }
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?)/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime })
    },
    onCapture () {
      this.img = this.$refs.webcam.capture()
      // var formData = new FormData()
      const file = this.dataURLtoFile(this.img, 'captureImageName')
      // formData.append('sampleFile', file, file.name)
      const fileData = {
        path: this.img,
        fileData: file
      }
      this.$emit('onCapture', fileData)
    },
    // 025660336
    onStarted (stream) {
      console.log('On Started Event', stream)
    },
    onStopped (stream) {
      console.log('On Stopped Event', stream)
    },
    onStop () {
      this.$refs.webcam.stop()
    },
    onStart () {
      this.$refs.webcam.start()
    },
    onError (error) {
      console.log('On Error Event', error)
    },
    onCameras (cameras) {
      this.devices = cameras
      console.log('On Cameras Event', cameras)
    },
    onCameraChange (deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      console.log('On Camera Change Event', deviceId)
    }
  },
  computed: {
    device: function () {
      return this.devices.find(n => n.deviceId === this.deviceId)
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id
    },
    devices: function () {
      // Once we have a list select the first one
      const [first] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    }
  }
}
</script>

<style lang="css" scoped>
  #camera {
    position: absolute;
    height: 80vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: black;
    z-index: 999;
  }

  #camera-screen {}

  #switch-button {
    border-radius: 50px;
    width: 70px;
    height: 70px;
    z-index: 999;
    align-self: center;
    background: var(--tgLight);
    color: black;
    background: none;
    border: none;
  }
  #close-button {
    border-radius: 50px;
    width: 70px;
    height: 70px;
    z-index: 999;
    align-self: center;
    border: none;
    background: none;
    color: black;
  }
  #capture-button {
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background: var(--tgBlue);
    color: var(--tgLight);
    font-weight: 600;
    border: 2px dashed white;
    border-spacing: 15px 50px;
    align-self: center;
  }
  #action-group {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 999;
    display: flex;
    flex-flow: row;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
    background: rgba(1,1,1, .4);
  }

  i {
    font-size: 2em;
    color: white;
  }
</style>
