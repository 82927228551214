<template>
  <div id="app">
    <!--
    <amplify-authenticator class="container" v-if="!signedIn">
      <amplify-sign-out></amplify-sign-out>
    </amplify-authenticator>
    -->
    <div>
      <the-header v-if="$store.state.signedIn">
      </the-header>
      <router-view/>
      <the-bottom-bar v-if="$store.state.signedIn"></the-bottom-bar>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import TheHeader from '@/components/TheHeader'
import TheBottomBar from '@/components/TheBottomBar'
import AuthMixin from '@/mixin/AuthMixins'
export default {
  mixins: [AuthMixin],
  data () {
    return {
      userData: '',
      userRole: 'staff',
      telephone: '',
      password: ''
    }
  },
  mounted () {
    this.getUserData()
  },
  methods: {
    getUserData () {
      Auth.currentAuthenticatedUser().then(response => {
        this.userData = response
      })

      Auth.userAttributes('jakkapan').then(response => {
        console.log(response)
      }).catch(e => {
        console.log(e)
      })
    }
  },
  computed: {
    signedIn () {
      return this.$store.state.signedIn
    }
  },
  components: {
    TheHeader,
    TheBottomBar
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300,400,600,700,800&display=swap');

body, html, #app {
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
}

body, html {

}

:root {
  --tgBlue: #2CD6FF;
  --tgDarkBlue: #29bde2;
  --tgBlueLight: #dee5e7;
  --tgLight: #FFFFFF;
  --success: #2bde46;
  --tgLightHover: #f1fcf9ee;
  --forground: #FFFFFF;
  --pending: #fd9d2c;
  --in-progress: #2CD6FF;
  --approved: #2bde46;
  --unapproved: #fd2c5c;
  --secondary: #6C6262;
}

.error {
  color: var(--unapproved);
  padding-top: 16px;
  text-align: center;
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity .0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.mt32 {
  margin-top: 32px;
}
</style>
