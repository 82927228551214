<template>
  <div class="middle middle-content">
    <grid-loader :color="'var(--tgBlue)'"></grid-loader>
    <p class="loading-title">Phacenix</p>
  </div>
</template>
<script>
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'
export default {
  components: {
    GridLoader
  }
}
</script>

<style lang="css" scoped>
.loading-title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: .4em;
}
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.middle-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0 auto;
}
</style>
