<template>
  <div class="table-view-cell" @click="$emit('click')">
    <div class="grid">
      <div class="date padding-10">
        <div class="datename">{{ weekday[getDate().dateName] }}</div>
        <div class="day">{{ getDate().createdDay }}</div>
      </div>
      <div class="status-color "></div>
      <div class="detail padding-10">
        <div class="name">{{ data.firstname }}</div>
        <div class="time">{{ toTime(data.start) }} - {{ toTime(data.end) }}</div>
        <div class="status">Un Approved</div>
        <div class="time-diff">-</div>
        <div class="time-diff">{{ data.timeDiff }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data () {
    return {
      weekday: {
        0: 'SUN',
        1: 'MON',
        2: 'TUE',
        3: 'WED',
        4: 'THU',
        5: 'FRI',
        6: 'SAT'
      }
    }
  },
  methods: {
    getDate () {
      return {
        createdDay: new Date(this.data.createAt).getUTCDate(),
        dateName: new Date(this.data.createAt).getDay()
      }
    },
    toTime (dateString) {
      if (dateString === '') {
        return ''
      }
      const _date = new Date(dateString)
      return _date.toLocaleTimeString().slice(0, -2)
    }
  }
}
</script>
<style lang="css" scoped>
  .table-view-cell {
    padding: 8px;
    min-height: 30px;
    margin-top: 2px;
    background: white;
  }

  .grid {
    height: 100%;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 50px 3px auto;
  }
  .status-color {
    background: var(--unapproved);
  }

  .detail > .name {
    font-weight: 600;
  }

  .detail > .time {
    font-weight: 300;
    font-size: .9em;
  }
  .detail > .status , .time-diff {
    font-weight: 600;
    margin-top: 8px;
    font-size: .9em;
    display: inline-block;
  }

  .detail > .status {
    color: var(--unapproved);
  }

  .detail > .time-diff {
    margin-left: 8px;
    font-size: .9em;
    font-weight: 400;
  }

  .date > .datename {
    font-weight: 600;
  }

  .padding-10 {
    padding: 10px;
  }
</style>
